import moment from 'moment';

function parseEntryType(ty) {
    switch (ty) {
        case 1:
            return 'Ankauf';
        case 2:
            return 'Verkauf';
        case 3:
            return 'Entnahme';
        case 4:
            return 'Gutschrift';
        case 5:
            return 'Sparplanrate';
        default:
            return `(Unbekannt: ${ty})`;
    }
}

function parseInstitution(ty) {
    return 'Kantonalbank';
    switch (ty) {
        case 1:
            return 'Kantonalbank';
        default:
            return `(Unbekannt: ${ty})'`;
    }
}




function EntryTable({ entriesData }) {
    let entry_number = 0;

    let tableData = entriesData
        .sort((a, b) => new Date(a.booking_date).getTime() - new Date(b.booking_date).getTime())
        .map((entry) => {
            entry_number++;
            return {
                number: entry_number,
                date: moment(entry.booking_date).format('DD.MM.YYYY'),
                institution: parseInstitution(entry.fk_deposit),
                transaction_type: parseEntryType(entry.entry_type),
                bullion_type: entry.bullion_type === 1 ? "Gold" : "Silber",
                sum: entry.amount_invested ? parseFloat(entry.amount_invested).toFixed(2) : "-",
                price: entry.bullion_rate ? parseFloat(entry.bullion_rate).toFixed(2) : "-",
                grammes: entry.grammes
            }
        }) || []
    return (
        <table className="table table-text-center">
            <thead className="thead-light">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Institut</th>
                    <th scope="col">Transaktionsart</th>
                    <th scope="col">Edelmetall</th>
                    <th scope="col">Summe</th>
                    <th scope="col">Kurs in EUR/g</th>
                    <th scope="col">Menge in g</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((dataset) => {
                    return (<tr>
                        <th scope="row">{dataset.number}</th>
                        <td>{dataset.date}</td>
                        <td>{dataset.institution}</td>
                        <td>{dataset.transaction_type}</td>
                        <td>{dataset.bullion_type}</td>
                        <td>{dataset.sum}</td>
                        <td>{dataset.price}</td>
                        <td>{dataset.grammes}</td>
                    </tr>)
                })}
            </tbody>
        </table>
    )
}

export default EntryTable
