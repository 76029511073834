import axios from 'axios';

const REFRESH = '/user/refresh';
var ax = axios.create({
    baseURL: "https://backend.stage.bullion-value.de"
});


function getLocalRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken;
  }

async function refresh() {
    try {
        let old_auth_string = localStorage.getItem("auth");
        let auth = JSON.parse(old_auth_string);
      const response = await ax.post(REFRESH, {
        refresh_token: auth.refreshToken
      });
      let accessToken = response.data.access_token;
      console.log("new accesstoken: " + accessToken);
      auth.accessToken = accessToken;
      let new_auth_string = JSON.stringify(auth);
      localStorage.setItem("auth", new_auth_string);
    } catch (err) {
      console.error(err);
      throw err;
    }
}


ax.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
  
      if (error.response?.status === 469 && !originalRequest._retry) {
        originalRequest._retry = true;
        return refresh().then(() => {
          let auth_string = localStorage.getItem("auth");
          let auth = JSON.parse(auth_string);
          console.log("hkashdjasadhsk: " + auth.accessToken)
            let data = JSON.parse(originalRequest.data);
            data.access_token = auth.accessToken;
            originalRequest.data = JSON.stringify(data);
          return axios(originalRequest);
        });
      }
      return Promise.reject(error);
    }
  );

export default ax;