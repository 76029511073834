import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';


//import logo from "../assets/svg/logos/logo.svg";
import logo from "../assets/img/bv_logo.png";
import backgroundImage from "../assets/img/background.png";
import googleicon from "../assets/svg/brands/google-icon.svg";
import { ROLES } from "../App.js"


import axios from '../api/axios';
const LOGIN_URL = '/user/login';

const Login = () => {
  const { setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!consent) {
      setErrMsg('Das Zustimmen der Datenschutzerklärung ist erforderlich.');
      errRef.current.focus()
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(LOGIN_URL,
        {
          username: user,
          password: pwd
        },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      const accessToken = response?.data?.access_token;
      const refreshToken = response?.data?.refresh_token;
      const roles = [response?.data?.role];
      setAuth({ user, pwd, roles, accessToken });
      localStorage.setItem("auth", JSON.stringify({ roles, accessToken, refreshToken }));
      setUser('');
      setPwd('');
      if (roles.includes(ROLES.Customer)) {
        navigate('/customer/dashboard', { replace: true });
      } else if (roles.includes(ROLES.Broker)) {
        navigate('/broker/dashboard', { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('Keine Server Verbindung');
      } else if (err.response?.status === 400) {
        setErrMsg('Fehlende(s) Kundennummer oder Passwort');
      } else if (err.response?.status === 401) {
        setErrMsg('Nicht befugt');
      } else {
        console.log("Error Message: ", err.response.data)
        setErrMsg('Login fehlgschlagen: ' + err);
      }
      errRef.current.focus();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          top: 0,
          left: 0,
          height: "100%",
          backgroundColor: "#f3f5f7",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "100% auto"
        }}
      >
      </div>
      {/* Content */}
      <div className="container py-5 py-sm-7">
        <Link to="/home"><a
          className="d-flex justify-content-center mb-5"
        >
          <img
            className="zi-2"
            src={logo}
            alt="Image Description"
            style={{ width: "8rem" }}
          />
        </a></Link>
        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          {/* Card */}
          <div className="card card-lg mb-5">
            <div className="card-body">
              {/* Form */}
              <form className="js-validate needs-validation" noValidate="" onSubmit={handleSubmit}>
                <div className="text-center">
                  <div className="mb-5">
                    <h1 className="display-5">Log in</h1>
                    <div className={errMsg ? "alert alert-soft-danger" : "offscreen"} role="alert">
                      {errMsg}
                    </div>
                    <p>
                      Hey, willkommen zurück!👋👋
                    </p>
                  </div>
                  {/*<span className="divider-center text-muted mb-4">OR</span>*/}
                </div>
                {/* Form */}
                <div className="mb-4">
                  <label className="form-label" htmlFor="signinSrEmail">
                    Kundennummer
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="username"
                    ref={userRef}
                    id="signinSrEmail"
                    onChange={(e) => setUser(e.target.value)}
                    tabIndex={1}
                    value={user}
                    placeholder=""
                    aria-label="00000"
                    required=""
                  />
                  <span className="invalid-feedback">
                    Please enter a valid email address.
                  </span>
                </div>
                {/* End Form */}
                {/* Form */}
                <div className="mb-4">
                  <label
                    className="form-label w-100"
                    htmlFor="signupSrPassword"
                    tabIndex={0}
                  >
                    <span className="d-flex justify-content-between align-items-center">
                      <span>Passwort</span>
                      <Link to="/forgotpassword"><a
                        className="form-label-link mb-0"
                      >
                        Passwort vergessen?
                      </a></Link>
                    </span>
                  </label>
                  <div
                    className="input-group input-group-merge"
                    data-hs-validation-validate-class=""
                  >
                    <input
                      type="password"
                      className="js-toggle-password form-control form-control-lg"
                      name="password"
                      id="signupSrPassword"
                      placeholder=""
                      aria-label=""
                      required=""
                      minLength={8}
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      data-hs-toggle-password-options='{
                     "target": "#changePassTarget",
                     "defaultClass": "bi-eye-slash",
                     "showClass": "bi-eye",
                     "classChangeTarget": "#changePassIcon"
                   }'
                    />
                    <a
                      id="changePassTarget"
                      className="input-group-append input-group-text"
                      href="javascript:;"
                    >
                      <i id="changePassIcon" className="bi-eye" />
                    </a>
                  </div>
                  <span className="invalid-feedback">
                    Bitte gebe ein valides Passwort ein.
                  </span>
                </div>
                {/* Form Check */}
                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    onChange={(e) => setConsent(e.currentTarget.checked)}
                    id="termsCheckbox"
                  />
                  <label className="form-check-label mt-1" htmlFor="termsCheckbox">
                    Ich stimme der
                    <a
                      className="form-label-link mb-0"
                      href="https://bullion-value.com/datenschutzerklaerung/" 
                      target="_blank"
                      rel="noreferrer"
                    >
                      &nbsp;Datenschutzerklärung&nbsp;
                    </a>
                    zu.
                  </label>
                </div>
                {/* End Form Check */}
                {/* End Form */}
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary btn-lg" style={{ height: "50px" }}>
                    {isLoading ? <div className="lds-ripple"><div></div><div></div></div> : "Log in"}
                  </button>
                </div>
              </form>
              {/* End Form */}
            </div>
          </div>
          {/* End Card */}
        </div>
      </div>
      {/* End Content */}
    </main>
  )
}

export default Login
