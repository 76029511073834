


const Address = ({ address }) => {
    if (!address) { 
      return (<></>);
    }
    return (
      <>
          <h3>Adresse</h3>
            <p className="card-text">
              {address.street ? address.street : ""}<br></br>
              {address.zip ? address.zip + " " : ""}
              {address.city ? address.city : ""}
            </p>
      </>
    );
}

export default Address;