import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import moment from 'moment';

const BROKER_CUSTOMERS_URL = '/broker/customers';

const BrokerCustomers = () => {
    const { auth } = useAuth();

    const [customerData, setCustomerData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [err, setErr] = useState('');

    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                BROKER_CUSTOMERS_URL,
                { access_token: auth.accessToken },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            let n = 0;
            const mappedData = data
                .sort((a, b) => (a.lastname > b.lastname) ? 1 : -1)
                .map((customer) => {
                    n += 1;
                    return {
                        number: n,
                        firstname: customer.firstname,
                        lastname: customer.lastname,
                        no: customer.customer_no
                    }
                })

            setSearchData(mappedData);
            setCustomerData(mappedData);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    function matches(customer, search) {
        const firstname = customer.firstname.trim().toLowerCase();
        const lastname = customer.lastname.trim().toLowerCase();
        const no = customer.no.trim();
        const fullname = (customer.firstname + " " + customer.lastname).trim().toLowerCase();
        const fullname_rev = (customer.lastname + " " + customer.firstname).trim().toLowerCase();
        search = search.trim().toLowerCase();
        return search.includes(firstname) || search.includes(lastname) || no.includes(search) || fullname.includes(search) || fullname_rev.includes(search);
    }

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        if (e.target.value.length > 0) {
            let results = customerData.filter((customer) => {
                return matches(customer, e.target.value);
            });
            setSearchData(results)
        } else {
            setSearchData(customerData)
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <h1>Kunden</h1>
            {isLoading ? (
                <div className="mx-auto" style={{ height: "100%", width: "36px" }}>
                    <div className="lds-ripple mx-auto" style={{ margin: 0 }}><div style={{ border: "2px solid #377dff" }}></div><div></div></div>
                </div>
            ) : (
                err ? (
                    <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
                        {err}
                    </div>
                ) : (
                    <div>
                        {/* Search */}
                        <div className="mb-4 w-md-50">
                            <div className="input-group input-group-merge">
                                <input
                                    type="text"
                                    className="js-form-search form-control"
                                    placeholder="Search..."
                                    onChange={handleChange}
                                    value={searchInput}
                                    data-hs-form-search-options='{
                "clearIcon": "#clearIcon2",
                "defaultIcon": "#defaultClearIconToggleEg"
                }'
                                />
                                <button type="button" className="input-group-append input-group-text">
                                    <i id="clearIcon2" className="bi-x-lg" style={{ display: "none" }} />
                                    <i
                                        id="defaultClearIconToggleEg"
                                        className="bi-search"
                                        style={{ display: "false" }}
                                    />
                                </button>
                            </div>
                        </div>
                        {/* Search */}
                        <table className="table table-text-center">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Kundennummer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchData.map((customer) => {
                                    return (<tr>
                                        <th scope="row">{customer.number}</th>
                                        <td> 
                                            <Link to={"customer/" + customer.no}>
                                                <a className="nav-link" href="#">
                                                    {customer.lastname + ", " + customer.firstname} 
                                                </a>
                                            </Link>
                                        </td>
                                        <td>{customer.no}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>)
            )}
        </div>
    )
}

export default BrokerCustomers