import Address from "../Address"

export const CustomerAboutMe = ({ aboutMeData, isLoading, err }) => {
  if (!aboutMeData) {
    return (<></>);
  }


  return (
    <div style={{width: "100%"}}>
        <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
              {err}
        </div>
      {isLoading ? ( 
        <div className="mx-auto" style={{height: "100%", width: "36px"}}>
          <div className="lds-ripple mx-auto" style={{margin: 0}}><div style={{border: "2px solid #377dff"}}></div><div></div></div>
        </div>
      ) : (
        <div>
          <PersonalData customer_data={aboutMeData.customer_data}/>
          <Address address={aboutMeData.address}/>
          <Settings customer_data={aboutMeData.customer_data}/>
        </div>
      )}
    </div>   
  )
}

const PersonalData = ({ customer_data }) => {
  if (!customer_data) {
    return (<></>);
  }
  return (
    <>
      <h3 className="title">Anschrift</h3>
          <p className="card-text">
            Kundennummer: {customer_data.customer_no ? customer_data.customer_no : "-"}<br></br>
            Name: {customer_data.firstname + " " + customer_data.lastname} <br></br>
            Geburtstag: {customer_data.date_of_birth ? customer_data.date_of_birth : "-"}<br></br>
            📧: {customer_data.email ? customer_data.email : "-"}<br></br>
            1. ☎️: {customer_data.phone1 ? customer_data.phone1 : "-"}<br></br>
            2. ☎️: {customer_data.phone2 ? customer_data.phone2 : "-"}
          </p>
    </>
  )
}

const Settings = ({ customer_data }) => {
  if(!customer_data) {
    return (<></>);
  }
  return (
    <>
      <h3 className="card-title">Einstellungen</h3>
        <p className="card-text">
          Tresormiete per Entnahme: {customer_data.withdraw_safe_rent ? "✔️" : "❌"}<br></br>
          Rechnung via E-Mail: {customer_data.bill_by_email ? "✔️" : "❌"}<br></br>
          Newsletter via E-Mail: {customer_data.send_newsletter ? "✔️" : "❌"}
        </p>
    </>
  )
}

export default CustomerAboutMe