import EntryTable from "../EntryTable";
import Chart from "../Chart";
import { useState, useEffect } from "react";
import axios from "../../api/axios"
import useAuth from "../../hooks/useAuth";
import React from 'react'
import { BULLION_TYPE } from "../../App";

const ENTRIES_URL = '/customer/entries';
const YIELDS_URL = '/customer/yields';
const STOCKVALUE_URL = '/customer/stock-values';

function Commodity({ bullion_id }) {
    const { auth } = useAuth();

    const [entriesData, setEntriesData] = useState([]);
    const [chartEntriesData, setChartEntriesData] = useState([]);
    const [yields, setYieldssData] = useState([]);
    const [stockValueData, setStockValuesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState('');

    useEffect(() => {
        getEntries(bullion_id);
        getYields(bullion_id);
        getStockValues(bullion_id);
    }, [bullion_id]);

    async function getEntries(bullion_id) {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                ENTRIES_URL,
                { 
                    access_token: auth.accessToken,
                    bullion_type: BULLION_TYPE[bullion_id],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setEntriesData(data)
            let total_amount = 0.0;
            let eD = data
            .sort((a, b) => { return new Date(a.booking_date).getTime() - new Date(b.booking_date).getTime() })
            .map((entry) => {
                total_amount += entry.grammes;
                return {
                    x: entry.booking_date,
                    y: total_amount,
                }
            });
            setChartEntriesData(eD);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    async function getStockValues(bullion_id) {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                STOCKVALUE_URL,
                { 
                    access_token: auth.accessToken,
                    bullion_type: BULLION_TYPE[bullion_id],
                    currency: 'Euro',
                    data_size: 300
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setStockValuesData(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getYields = async (bullion_id) => {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                YIELDS_URL,
                { 
                    bullion_type: BULLION_TYPE[bullion_id],
                    access_token: auth.accessToken,
                    currency: 'Euro',
                    data_size: 300,
                    unit: 'Percentage'
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );

            setYieldssData(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div style={{width:"100%"}}>
             <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
                {err}
            </div>
            <h1>{title(bullion_id)}</h1>
            <h3>{stockValueData[stockValueData.length - 1] ? parseFloat(stockValueData[stockValueData.length - 1].y).toFixed(2) + "€" : "0€"}</h3>
            {grammes(chartEntriesData[chartEntriesData.length - 1], bullion_id)}
            {isLoading ? (
                    <div className="mx-auto" style={{height: "100%", width: "36px"}}>
                      <div className="lds-ripple mx-auto" style={{margin: 0}}><div style={{border: "2px solid #377dff"}}></div><div></div></div>
                    </div>
            ) : (
                <>
                    <Chart id={bullion_id} yields={yields} entriesData={chartEntriesData} stockValueData={stockValueData}></Chart>
                    {entriesData.length > 0 ? (<EntryTable entriesData={entriesData}></EntryTable>) : (<></>)}
                </>
            )
            }
        </div>

    )
}

function title(bullion_id) {
    switch (bullion_id) {
        case 1:
            return 'Gold'
        case 2:
            return 'Silber'
        case 3:
            return 'Gesamt'
    }
}

function grammes(last_entry, bullion_id) {
    if (bullion_id > 2) {
        return ''
    }
    return (<a>
        {last_entry ? parseFloat(last_entry.y).toFixed(1) + "g" : "0g"}
    </a>)
}

export default Commodity