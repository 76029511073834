import { useContext, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import moment from 'moment';
import CustomerAboutMe from "./CustomerAboutMe";

const ABOUTME_URL = '/customer/aboutme';

const AboutMe = () => {
    const { auth } = useAuth();

    const [aboutMeData, setAboutMeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState('');

    useEffect(() => {
      getAboutMeData();
    }, []);

    const getAboutMeData = async () =>  {
        setIsLoading(true);
        try {
          const {data} = await axios.post(
            ABOUTME_URL,
            {access_token: auth.accessToken},
            {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            },
          );  
          setAboutMeData(data);
        } catch (err) {
          setErr(err.message);
        } finally {
          setIsLoading(false);
        }
      };

    return (
      <div>
        <h1 className="title">Meine Daten</h1>
        <CustomerAboutMe isLoading={isLoading} aboutMeData={aboutMeData} err={err}/>
      </div>   
    )
}

export default AboutMe