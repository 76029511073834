import { useState } from "react";
import moment from 'moment';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment'
ChartJS.register(LinearScale);
ChartJS.register(PointElement);
ChartJS.register(Title);
ChartJS.register(LineElement);
ChartJS.register(Tooltip);
ChartJS.register(Legend);
ChartJS.register(TimeScale);



function Chart({ yields, entriesData, stockValueData }) {


    const data = {
        datasets: [
            {
                label: 'Preis',
                data: stockValueData,
                borderColor: 'rgba(55, 125, 255, 1.0)',
                backgroundColor: 'rgba(55, 125, 255, 0.33)',
                pointBackgroundColor: 'white',
                borderWidth: 2,
                tension: 0.4,
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: "rgba(255, 0, 0, 0.3)"
                },
                yAxisID: 'y',
            },
            {
                label: 'Bestand',
                data: entriesData,
                borderColor: 'rgba(9, 165, 190, 1.0)',
                backgroundColor: 'rgba(9, 165, 190, 0.33)',
                pointBackgroundColor: 'white',
                borderWidth: 2,
                yAxisID: 'y1',
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: "rgba(255, 0, 0, 0.3)"
                },
            },
            {
                label: 'Ertrag',
                data: yields,
                borderColor: 'rgba(131, 51, 222, 1.0)',
                backgroundColor: 'rgba(131, 51, 222, 0.33)',
                pointBackgroundColor: 'white',
                borderWidth: 2,
                yAxisID: 'y2',
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: "rgba(255, 0, 0, 0.3)"
                },
            },
        ]
    }

    const options = {
        plugins: {
            filler: {
                propagate: "true",
            },
            legend: {
                display: true,
                position: 'top',
                align: 'center',
                labels: {
                    useBorderRadius: true,
                    borderRadius: '250px',
                    color: '#677788',
                }
            },
            tooltip: {
                // See: https://stackoverflow.com/a/44010778/1762224
                backgroundColor: "#132144",
                titleColor: "#e6e7eb",
                bodyColor: "#white",
                titleFontStyle: "bold",
                displayColors: false,
                bodyFontColor: "black",
                borderColor: "black",
                borderWidth: 1,
              }
        },
        elements: {
            point:{
                radius: 0,
            },
        },
        scales: {
            x: {
                type: 'time',
                grid: {
                    drawOnChartArea: false,
                },
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                display: true,
                position: 'left',
                ticks: {
                    // Include a euro sign in the ticks
                    callback: function(value, index, ticks) {
                        return value + '€';
                    }
                }
            },
            y1: {
                type: 'linear',
                display: entriesData.length > 0,
                position: 'right',
                beginAtZero: true,
                ticks: {
                    // Include a gramm amount in the ticks
                    callback: function(value, index, ticks) {
                        return value + 'g';
                    }
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            y2: {
                type: 'linear',
                display: yields.length > 0,
                position: 'left',
                beginAtZero: true,
                ticks: {
                    // Include a gramm amount in the ticks
                    callback: function(value, index, ticks) {
                        return value + '%';
                    }
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    }

    return (
        <div className="chartjs-custom">
            <Line options={options} data={data} />
        </div>
    )
}

export default Chart