import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import * as React from 'react'
import CustomerAboutMe from "../customer/CustomerAboutMe";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import EntryTable from "../EntryTable";
import Chart from "../Chart";
import { BULLION_TYPE } from "../../App";


const TabView = ({ customer_no }) => {
    const [selected, setSelected] = useState(0);
    const tabs = [
        { index: 0, name: "Kundendaten", tab: (<BrokerCustomerAboutWrapper customer_no={customer_no} />) },
        { index: 1, name: "Gesamt", tab: (<BrokerCustomerCommodityWrapper bullion_id={3} customer_no={customer_no} />)},
        { index: 2, name: "Gold", tab: (<BrokerCustomerCommodityWrapper bullion_id={1} customer_no={customer_no} />)},
        { index: 3, name: "Silber", tab: (<BrokerCustomerCommodityWrapper bullion_id={2} customer_no={customer_no} />)},
    ];

    //var selected = 1;

    return (
        <>
            <div className="text-center" style={{ width: "100%", marginTop: "10px"}}>
                <ul className="nav nav-segment nav-pills mb-7" role="tablist">
                    {tabs.map((tab) => {
                        return (
                            <li className="nav-item">
                                <a 
                                    onClick={function(e) { setSelected(tab.index);}} 
                                    class={"nav-link" + (tab.index === selected ? " active" : "")} 
                                    id="nav-three-eg1-tab" 
                                    data-bs-toggle="pill" 
                                    data-bs-target="#nav-three-eg1" 
                                    role="tab" aria-controls="nav-three-eg1" 
                                    aria-selected={tab.index === selected ? "true" : "false"}
                                    style={{cursor: "pointer"}}
                                >
                                    {tab.name}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
            {tabs[selected].tab}
        </>
    )
}

const CUSTOMER_ABOUT_ME_URL = "/broker/customer/about"
const ENTRIES_URL = '/broker/customer/entries';
const YIELDS_URL = '/broker/customer/yields';
const STOCK_VALUE_URL = '/broker/customer/stock-values';

const BrokerCustomerAboutWrapper = ({ customer_no }) => {
    const { auth } = useAuth();

    const [aboutMeData, setAboutMeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState('');

    useEffect(() => {
        getAboutMeData();
    }, []);

    const getAboutMeData = async () =>  {
        setIsLoading(true);
        try {
          const {data} = await axios.post(
            CUSTOMER_ABOUT_ME_URL,
            {
                customer_no: customer_no,
                access_token: auth.accessToken
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            },
          ); 
          setAboutMeData(data);
        } catch (err) {
          setErr(err.message);
        } finally {
          setIsLoading(false);
        }
    };
    return (<CustomerAboutMe isLoading={isLoading} aboutMeData={aboutMeData} err={err}/>)
}

const BrokerCustomerCommodityWrapper = ({ customer_no, bullion_id }) => {
    const { auth } = useAuth();

    const [entriesData, setEntriesData] = useState([]);
    const [chartEntriesData, setChartEntriesData] = useState([]);
    const [yields, setYieldssData] = useState([]);
    const [stockValueData, setStockValuesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState('');

    useEffect(() => {
        getAllEntries(bullion_id);
        getStockValues(bullion_id);
        getYields(bullion_id);

    }, [bullion_id]);

    async function getAllEntries() {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                ENTRIES_URL,
                { 
                    access_token: auth.accessToken,
                    bullion_type: BULLION_TYPE[bullion_id],
                    customer_no: customer_no
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setEntriesData(data)
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    async function getStockValues(id) {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                STOCK_VALUE_URL,
                { 
                    access_token: auth.accessToken,
                    currency: "Euro",
                    data_size: 300,
                    bullion_type: BULLION_TYPE[bullion_id],
                    customer_no: customer_no
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );
            setStockValuesData(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getYields = async (bullion_id) => {
        setIsLoading(true);
        try {
            const { data } = await axios.post(
                YIELDS_URL,
                { 
                    bullion_type: BULLION_TYPE[bullion_id],
                    access_token: auth.accessToken,
                    currency: 'Euro',
                    data_size: 300,
                    unit: 'Percentage',
                    customer_no: customer_no
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            );

            setYieldssData(data);
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div style={{width:"100%"}}>
             <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
                {err}
            </div>
            <h1>{title(bullion_id)}</h1>
            <h3>{stockValueData[stockValueData.length - 1] ? parseFloat(stockValueData[stockValueData.length - 1].y).toFixed(2) + "€" : "0€"}</h3>
            {grammes(chartEntriesData[chartEntriesData.length - 1], bullion_id)}
            {isLoading ? (
                    <div className="mx-auto" style={{height: "100%", width: "36px"}}>
                      <div className="lds-ripple mx-auto" style={{margin: 0}}><div style={{border: "2px solid #377dff"}}></div><div></div></div>
                    </div>
            ) : (
                <>
                    <Chart id={bullion_id} yields={yields} entriesData={chartEntriesData} stockValueData={stockValueData}></Chart>
                    {entriesData.length > 0 ? (<EntryTable entriesData={entriesData}></EntryTable>) : (<></>)}
                </>
            )
            }
        </div>
    )
}

function title(bullion_id) {
    switch (bullion_id) {
        case 1:
            return 'Gold'
        case 2:
            return 'Silber'
        case 3:
            return 'Gesamt'
    }
}

function grammes(last_entry, bullion_id) {
    if (bullion_id > 2) {
        return ''
    }
    return (<a>
        {last_entry ? parseFloat(last_entry.y).toFixed(1) + "g" : "0g"}
    </a>)
}

const BrokerCustomer = () => {
    const { customer_no } = useParams();
    useEffect(() => {
        //getCustomers();
    }, []);



    return (
        <div style={{width: "100%"}}>
            <h1>Kundenübersicht</h1>
            <a>Kundennummer: {customer_no}</a>

            <TabView customer_no={customer_no} />


        </div>
    )
}


export default BrokerCustomer;