import { useNavigate, Link } from "react-router-dom";
import { useContext, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import { Outlet } from 'react-router-dom';
import React from 'react'
import axios from "../../api/axios"
import logo from "../../assets/img/bv_logo.png";

const LOGOUT_URL = "/user/logout"

const BrokerDashboard = () => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');

    const logout = async () => {
        setIsLoading(true);
        const auth = localStorage.getItem("auth");
        try {
            if (auth.refresh_token) {
                axios.post(
                    LOGOUT_URL,
                    {
                        refresh_token: auth.refresh_token
                    },
                    {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                    }
                );
            }
            localStorage.removeItem("auth");
            setAuth({});
            navigate('/login');
        } catch (err) {
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const HorizontalNavBar = () => {
        return(
            <header className="docs-navbar navbar navbar-expand-lg navbar-end navbar-light bg-white" style={{borderBottom: "0.0625rem solid rgba(231, 234, 243, 0.7)"}}>
            <div className="container">
              <div className="js-mega-menu navbar-nav-wrap">
                {/* Logo */}
                <Link to="/home" style={{display: "block", marginTop: "0.5rem" }}>
                        <img className="navbar-brand-logo mx-auto" src={logo} alt="Logo" data-hs-theme-appearance="default" style={{display: "block"}}></img>
                </Link>
                {/* End Logo */}
                <nav
                  className="navbar-nav-wrap-col collapse navbar-collapse"
                  id="navbarNavMenuWithMegaMenu"
                >
                  {/* Navbar */}
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Menu
                      </a>
                    </li>
                  </ul>
                  {/* End Navbar */}
                </nav>
              </div>
            </div>
          </header>
        )
    }

    const VerticalNavBar = () => {
        return (
            <div className="navbar-vertical-container" style={{ backgroundColor: "white", height: "100%", width: "15rem", zIndex: 100, position: "fixed", borderRight: "0.0625rem solid rgba(231, 234, 243, 0.7)"}}>
                <div className="navbar-vertical-content">

                    <Link to="/home" style={{width: "100%", display: "block", marginTop: "2rem" }}>
                        <img className="navbar-brand-logo mx-auto" src={logo} alt="Logo" data-hs-theme-appearance="default" style={{display: "block"}}></img>
                    </Link>

                    <div
                        className="navbar-expand-lg navbar-vertical mb-3 mb-lg-5"
                        style={{ maxWidth: "15rem" }}
                    >
                        {/* Navbar Collapse */}
                        <div
                            id="navbarVerticalNavMenuCardTabs"
                            className="collapse navbar-collapse"
                        >
                            <div
                                id="navbarSettingsCardWithNavTab"
                                className="card-navbar-nav nav nav-tabs nav-vertical"
                            >
                                <span className="dropdown-header">Debotübersicht</span>
                                <Link to="./">
                                    <a className="nav-link" href="#">
                                        <i className="bi-sliders nav-icon" /> Kunden
                                    </a>
                                </Link>
                                <Link to="briefing">
                                    <a className="nav-link" href="#">
                                        <i className="bi-sliders nav-icon" /> Auswertungen
                                    </a>
                                </Link>
                                <div className="dropdown-divider" />
                                <span className="dropdown-header">Account</span>
                                <Link to="aboutme">
                                    <a className="nav-link" href="#">
                                        <i className="bi-sliders nav-icon" /> Meine Daten
                                    </a>
                                </Link>
                                <a className="nav-link" onClick={logout} style={{cursor: "pointer"}}>
                                    <i className="bi-box-arrow-right nav-icon"></i> Log Out
                                </a>
                            </div>
                        </div>
                        {/* End Navbar Collapse */}
                    </div>
                    {/* End Navbar */}

                </div>
            </div>
        )
    }

    return (
        <div>
            { true ? (
                <div>
                    <VerticalNavBar></VerticalNavBar>
                    <div style={{ marginLeft: "15rem", padding: "20px", display: "flex" }}>
                        <Outlet></Outlet>
                    </div>
                </div>
            ) : (
                <div>
                    <HorizontalNavBar></HorizontalNavBar>
                    <div style={{ padding: "20px", display: "flex" }}>
                        <Outlet></Outlet>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BrokerDashboard
