


const Briefing = () => {
    return (
        <div>
            <h1>Auswertungen</h1>
            <p>coming soon ...</p>
        </div>
    )
}



export default Briefing;