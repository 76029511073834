import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Layout from './components/Layout';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import AboutMe from './components/customer/AboutMe';
import Commodity from './components/customer/Commodity';

import BrokerDashboard from './components/broker/BrokerDashboard';
import BrokerCustomers from './components/broker/BrokerCustomers';
import BrokerCustomer from './components/broker/BrokerCustomer';
import BrokerAboutMe from './components/broker/BrokerAboutMe';
import Briefing from './components/broker/Briefing';

import useScript from './hooks/useScript';
import useAuth from './hooks/useAuth';
import { useEffect, useContext } from "react";
import AuthContext from "./context/AuthProvider";

export const API_URL = 'http://localhost:8080/';

export const ROLES = {
  'Broker': 4,
  'Customer': 2,
  'Admin': 5150
}

export const CURRENCY = {
  'EURO': 1,
  'GDP': 2,
  'USD': 3
}

export const BULLION_TYPE = {
  1: 'Gold',
  2: 'Silver',
  3: 'Both',
}

function App() {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const auth_string = localStorage.getItem("auth");
  useEffect(() => {
    const auth = JSON.parse(auth_string);
    if (auth) {
      setAuth(auth);
    }
    if (auth_string && location.pathname === '/') {
      if (auth.roles?.includes(ROLES.Customer)) {
        navigate('customer/dashboard', { replace: true });
      } else if (auth.roles?.includes(ROLES.Broker)) {
        navigate('broker/dashboard', { replace: true });
      }
    } else {
      navigate(location.pathname, { replace: true });
    }
  }, [auth_string]);


  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* we want to protect these routes */}

        <Route path="/customer" element={<RequireAuth allowedRoles={[ROLES.Customer]} />}>
          <Route path="dashboard" element={< Dashboard />}>
            <Route path="aboutme" element={<AboutMe />}/>
            <Route path="" element={<Commodity bullion_id={3}/>}/>
            <Route path="gold" element={<Commodity bullion_id={1} />}/>
            <Route path="silver" element={<Commodity bullion_id={2} />}/>
            <Route path="*" element={<Missing />} />
          </Route>
        </Route>

        <Route path="/broker" element={<RequireAuth allowedRoles={[ROLES.Broker]} />}>
          <Route path="dashboard" element={< BrokerDashboard />}>
            <Route path="aboutme" element={<BrokerAboutMe />}/>
            <Route path="briefing" element={<Briefing />}/>
            <Route path="customer/:customer_no" element={<BrokerCustomer />}/>
            <Route path="" element={<BrokerCustomers/>}/>
            <Route path="*" element={<Missing />} />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="admin" element={<Admin />} />
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;