import { useContext, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import moment from 'moment';
import Address from "../Address"

const BROKERABOUTME_URL = 'broker/aboutme';

const BrokerAboutMe = () => {
    const { auth } = useAuth();

    const [aboutMeData, setAboutMeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState('');

    useEffect(() => {
        getAboutMeData();
    }, []);

    const getAboutMeData = async () =>  {
        setIsLoading(true);
        try {
          const {data} = await axios.post(
            BROKERABOUTME_URL,
            {access_token: auth.accessToken},
            {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            },
          ); 
          setAboutMeData(data);
        } catch (err) {
          setErr(err.message);
        } finally {
          setIsLoading(false);
        }
      };

    return (
      <div>
        <h1 className="title">Meine Daten</h1>
        {isLoading ? ( 
          <div className="mx-auto" style={{height: "100%", width: "36px"}}>
            <div className="lds-ripple mx-auto" style={{margin: 0}}><div style={{border: "2px solid #377dff"}}></div><div></div></div>
          </div>
        ) : ( err ? (
                <div className={err ? "alert alert-soft-danger" : "offscreen"} role="alert">
                    {err}
                </div>
            ) : (
              <BrokerAbout about_broker={aboutMeData}/>
            )
        )}
      </div>   
    )
}

const BrokerAbout = ({ about_broker }) => {
  if (!about_broker) {
    return (<></>)
  }
  return (
    <div>
        <PersonalData broker_data={about_broker.broker_data}/>
        <Address address={about_broker.address}/>
        <h3 className="card-title">Einstellungen</h3>
        <p className="card-text">
        Korrespondenz via E-Mail: {about_broker.broker_data.correspondence_by_email ? "✔️" : "❌"}<br></br>
        Newsletter via E-Mail: {about_broker.broker_data.send_newsletter ? "✔️" : "❌"}<br></br>
        </p>
        <h3 className="card-title">Agio</h3>
        <p className="card-text">
        Aktuelles Vertrags-Agio: {about_broker.broker_data.agio ? about_broker.broker_data.agio : ""}
        </p>
    </div>
  )

}

const PersonalData = ({ broker_data }) => {
  if(!broker_data) {
    return (<></>);
  }
  return (
    <>
       <h3 className="title">Anschrift</h3>
          <p className="card-text">
          Marklernummer: {broker_data?.broker_no ? broker_data.broker_no : "-"}<br></br>
          Name: {broker_data.firstname + " " + broker_data.lastname}<br></br>
          Geburtstag: {broker_data.date_of_birth ? moment(broker_data.date_of_birth).format('DD.MM.YYYY') : "-"}<br></br>
          📧: {broker_data.email ? broker_data.email : "-"}<br></br>
          1. ☎️: {broker_data.phone1 ? broker_data.phone1 : "-"}<br></br>
          2. ☎️: {broker_data.phone2 ? broker_data.phone2 : "-"}<br></br>
          fax: {broker_data.fax ? broker_data.fax : "-"}
          </p>
    </>
  )
}

export default BrokerAboutMe